<template>
  <v-dialog
    @input="(v) => $emit('input', v)"
    :value="value"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        Votar em: <b class="ml-2">{{ title }}</b>
      </v-card-title>

      <v-card-text class="mt-2">
        <MyTeacherFinalistsForm
          :action="files + '/rest/email/myteacher-finalists'"
          :response-to="to + '/votar/obrigado'"
          :teacher-id="id"
        >
        </MyTeacherFinalistsForm>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MyTeacherFinalistsForm from "../../forms/MyTeacherFinalistsForm.vue";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },

  components: {
    MyTeacherFinalistsForm,
  },
};
</script>
