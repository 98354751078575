import { render, staticRenderFns } from "./ProductsListView.vue?vue&type=template&id=58640cc6&scoped=true&"
import script from "./ProductsListView.vue?vue&type=script&lang=js&"
export * from "./ProductsListView.vue?vue&type=script&lang=js&"
import style0 from "./ProductsListView.vue?vue&type=style&index=0&id=58640cc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58640cc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VAlert,VContainer,VPagination})
