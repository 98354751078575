var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"my-teacher-view overflow-hidden"},[_c('div',{staticClass:"my-teacher-header position-relative",class:{
      'd-flex justify-center my-teacher-header-video': !_vm.finalists.length,
    }},[(_vm.finalists.length)?[_c('div',{staticClass:"position-relative my-teacher-header-finalists"},[_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[_c('img',{staticClass:"d-block position-relative",attrs:{"src":_vm.files + _vm.informations.img,"alt":_vm.informations.title}}),(_vm.today < _vm.finishDate)?[_c('div',{staticClass:"\n                text-h5 text-md-h4 text-center\n                font-weight-bold\n                mt-6\n                my-teacher-finalists-title\n              "},[_vm._v(" Qual professor você deseja mandar de férias para um resort? ")])]:_vm._e()],2),(_vm.today < _vm.finishDate)?[_c('v-container',{staticClass:"py-10 my-teacher-finalists"},_vm._l((_vm.finalists),function(finalist){return _c('FinalistCard',_vm._b({key:finalist.img},'FinalistCard',finalist,false))}),1)]:[_c('div',{staticClass:"d-flex justify-center"},[_c('v-alert',{staticClass:"mt-8 mt-md-10",attrs:{"type":"info"}},[_vm._v(" Votação Encerrada ")])],1)]],2)]:[(_vm.informations.video)?[_c('v-sheet',{staticClass:"video position-relative",attrs:{"color":"black","max-width":"680px","width":"100%"}},[_c('iframe',{attrs:{"width":"560","height":"315","src":("https://www.youtube.com/embed/" + (_vm.getYoutubeCode(
              _vm.informations.video
            )) + "?autoplay=1"),"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; mute; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})])]:[_c('img',{staticClass:"d-block position-relative",attrs:{"src":_vm.files + _vm.informations.img,"alt":_vm.informations.title}})]]],2),(_vm.wishes.length)?[_c('div',{staticClass:"my-teacher-content position-relative"},[_c('v-container',{staticClass:"px-10 px-md-3"},[_c('section',[_c('div',{staticClass:"my-teacher-section-title"},[_vm._v("Placar")]),_c('div',{staticClass:"d-flex py-2 item-wishe font-weight-bold"},[_c('div',[_vm._v("NOME")]),_c('div',{staticClass:"ml-auto"},[_vm._v("VOTOS")])]),_vm._l((_vm.wishes),function(ref){
            var title = ref.title;
            var wishes = ref.wishes;
return _c('div',{key:title + wishes,staticClass:"d-flex py-2 item-wishe"},[_c('div',[_vm._v(_vm._s(title))]),_c('div',{staticClass:"ml-auto"},[_vm._v(" "+_vm._s(wishes)+" ")])])})],2)])],1)]:_vm._e(),_c('div',{staticClass:"my-teacher-content position-relative",class:{ 'mt-10': _vm.wishes.length }},[_c('v-container',{staticClass:"px-10 px-md-3"},[_c('section',[_c('div',{staticClass:"my-teacher-section-title"},[_vm._v("Premiações")]),_c('div',{staticClass:"my-teacher-awards mt-8"},_vm._l((_vm.awards),function(ref,id){
            var title = ref.title;
            var award = ref.award;
return _c('div',{key:id},[_c('div',{staticClass:"text-h5 text-md-h4 text-uppercase font-weight-bold"},[_vm._v(" "+_vm._s(title)+" ")]),_c('div',{staticClass:"my-teacher-awards-text text-h6 mt-2",domProps:{"innerHTML":_vm._s(award)}})])}),0)])]),_c('section',{staticClass:"my-teacher-timeline mt-10"},[_c('v-container',{staticClass:"px-10 px-md-3"},[_c('div',{staticClass:"my-teacher-section-title"},[_vm._v("Cronograma")]),_c('div',{staticClass:"mt-6"},[_vm._l((_vm.timeline),function(ref,id){
            var title = ref.title;
            var date = ref.date;
            var description = ref.description;
return [_c('div',{key:id,staticClass:"pb-4"},[_c('div',{staticClass:"\n                  my-teacher-timeline-title\n                  text-subtitle-1 text-uppercase\n                  font-weight-bold\n                "},[_vm._v(" "+_vm._s(title)+" ("+_vm._s(date)+") ")]),_c('div',{staticClass:"text-subtitle-1",domProps:{"innerHTML":_vm._s(description)}})])]})],2)])],1),_c('section',{staticClass:"my-teacher-term"},[_c('v-container',{staticClass:"px-10 px-md-3"},[_c('div',{staticClass:"my-teacher-section-title"},[_vm._v("Regulamento")]),_c('div',{staticClass:"mt-8"},[_c('v-btn',{staticClass:"\n              text-body-1 text-md-h6 text-none\n              font-weight-bold\n              px-10\n              btn-term\n            ",attrs:{"href":_vm.files + _vm.informations.term,"target":"_blank","color":"#ffed00","depressed":"","x-large":"","rounded":""}},[_vm._v(" Clique Aqui e Baixe o Regulamento ")])],1)])],1)],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }