<template>
  <div>
    <HeroSection />
    <ProductsSection />
    <BannerSection />
    <ServicesSection />
    <StoresSection />
    <MapSection />
  </div>
</template>

<script>
import HeroSection from "../components/sections/hero/HeroSection.vue";
import ProductsSection from "../components/sections/ProductsSection.vue";
import BannerSection from "../components/sections/BannerSection.vue";
import ServicesSection from "../components/sections/ServicesSection.vue";
import StoresSection from "../components/sections/StoresSection.vue";
import MapSection from "../components/sections/MapSection.vue";

export default {
  components: {
    HeroSection,
    ProductsSection,
    BannerSection,
    ServicesSection,
    StoresSection,
    MapSection,
  },
};
</script>
