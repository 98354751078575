<template>
  <BaseForm
    class="myteacher-finalists-form"
    :class="{ small: small }"
    :action="action"
    :response-to="responseTo"
    gtm-submit-event="form_submit_myteacher-finalists"
  >
    <template #fields>
      <slot name="default" />
      <NameField />
      <TelField />
      <PolicyPrivacyField />
      <input type="hidden" name="myteacher_id" :value="teacherId" />

      <div
        class="g-recaptcha"
        data-sitekey="6LekK5QoAAAAAFtNoRLdEMlWSnurITnhzMLWz_uF"
      ></div>

      <div
        class="red--text text-caption mt-1"
        id="g-recaptcha-error"
        style="display: none"
      >
        Faça a verificação antes de continuar
      </div>
    </template>
  </BaseForm>
</template>

<script>
import BaseForm from "./BaseForm.vue";
import NameField from "./fields/NameField.vue";
import TelField from "./fields/TelField.vue";
import PolicyPrivacyField from "./fields/PolicyPrivacyField.vue";

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    responseTo: {
      type: String,
      required: true,
    },
    teacherId: {
      type: Number,
      required: true,
    },
  },
  created() {
    var script = document.createElement("script"); // create a script DOM node
    script.src = "https://www.google.com/recaptcha/api.js"; // set its src to the provided URL

    document.head.appendChild(script);
  },
  components: {
    BaseForm,
    NameField,
    TelField,
    PolicyPrivacyField,
  },
};
</script>

<style lang="scss">
.myteacher-finalists-form {
  .base-form-fields {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}
</style>
