<template>
  <v-app>
    <LoadingWidget v-if="loading" />

    <template v-else>
      <HeaderShell v-if="showShell" />

      <v-main>
        <router-view v-bind="$route.meta" />
      </v-main>

      <FooterShell v-if="showShell" />
    </template>

    <CookieBannerWidget />
    <WhatsAppButtonWidget
      v-if="informations.whatsapp"
      :tel="informations.whatsapp"
    />
  </v-app>
</template>

<script>
import HeaderShell from "./components/shell/header/HeaderShell.vue";
import FooterShell from "./components/shell/FooterShell.vue";
import CookieBannerWidget from "./components/widgets/CookieBannerWidget.vue";
import LoadingWidget from "./components/widgets/LoadingWidget.vue";
import WhatsAppButtonWidget from "./components/widgets/WhatsAppButtonWidget.vue";

export default {
  data: () => ({
    loading: true,
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    name() {
      return this.informations.name || "";
    },
    showShell() {
      const removeWhere = [
        "/professor",
        "/professor/obrigado",
        "/professor/:item",
        "/professor/:item/votar",
        "/professor/:item/votar/obrigado",
      ];

      return !removeWhere.includes(this.$route.matched[0].path);
    },
  },
  methods: {
    updateDocumentTitle() {
      document.title = !this.name
        ? this.$route.name
        : `${this.$route.name} | ${this.name}`;
    },
  },
  watch: {
    $route() {
      this.updateDocumentTitle();
    },
    name() {
      this.updateDocumentTitle();
    },
  },
  created() {
    this.updateDocumentTitle();
  },
  mounted() {
    this.$rest("informations")
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  components: {
    HeaderShell,
    FooterShell,
    CookieBannerWidget,
    LoadingWidget,
    WhatsAppButtonWidget,
  },
};
</script>
