<template>
  <v-container class="py-10 py-md-13 py-lg-16" :fluid="mdAndDown">
    <TitleWidget>Serviços</TitleWidget>

    <LoadingWidget v-if="loading" />
    <transition name="fade">
      <div v-if="!loading">
        <div v-if="items.length" class="mt-10 grid-list">
          <template v-for="(item, keyItems) in items">
            <ServiceCard
              v-if="item.title && item.img"
              :key="keyItems"
              v-bind="item"
            />
          </template>
        </div>

        <v-alert v-else type="info" color="secondary" class="white--text mt-10">
          Nenhum item encontrado no momento.
        </v-alert>

        <v-pagination
          v-if="pagesLength > 1"
          class="mt-10"
          color="secondary"
          v-model="page"
          :length="pagesLength"
        ></v-pagination>
      </div>
    </transition>
  </v-container>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import ServiceCard from "../components/cards/ServiceCard.vue";

const _config = {
  mod: "services",
  itemsPerPage: 24,
  returnTotalItems: 1,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    path: _config.path,
    itemsPerPage: _config.itemsPerPage,
    totalItems: 0,
    dialog: true,
  }),
  created() {
    this.get();
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    get() {
      this.loading = true;

      const params = {
        itemsPerPage: this.itemsPerPage,
        returnTotalItems: _config.returnTotalItems,
      };

      if (this.page) params.page = this.page;

      this.$rest(_config.mod)
        .get({
          params,
          keepCache: true,
        })
        .then((data) => {
          if (_config.returnTotalItems) {
            this.totalItems = data.totalItems;
            data = data.list;
          }

          this.items = data;

          if (!data.length && this.page > 1) this.page = 1;
        })
        .then(() => (this.loading = false));
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    ServiceCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    gap: 12px;
  }
}
</style>
