<template>
  <div class="base-form">
    <v-form @submit="submit" ref="form">
      <div class="base-form-fields">
        <slot name="fields"> </slot>
      </div>

      <ButtonWidget class="rounded-sm mt-4" color="#FFED00" type="submit" block>
        Enviar
      </ButtonWidget>
    </v-form>

    <v-overlay v-model="loading">
      <LoadingWidget />
    </v-overlay>
    <v-snackbar
      v-model="snackbarOpen"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      centered
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="snackbarOpen = false"
          color="white"
          text
          icon
          small
        >
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import ButtonWidget from "../widgets/ButtonWidget.vue";

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    responseTo: String,
    responseText: String,
    gtmSubmitEvent: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    snackbarOpen: false,
    snackbarText: "Lorem Ipsum",
    snackbarColor: "Grey",
    snackbarTimeout: 3000,
  }),
  methods: {
    notificate(text, color) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbarOpen = true;
    },
    async submit(event) {
      event.preventDefault();

      const form = this.$refs.form;

      if (document.getElementById("g-recaptcha-response")) {
        if (document.getElementById("g-recaptcha-response").value == "") {
          document.getElementById("g-recaptcha-error").style.display = "block";
          return false;
        } else
          document.getElementById("g-recaptcha-error").style.display = "none";
      }

      if (form.validate()) {
        this.loading = true;

        const formData = new FormData(form.$el);

        try {
          await fetch(this.action, {
            method: "POST",
            body: formData,
          })
            .then((data) => {
              return data.json();
            })
            .then((response) => {
              if (response.ok) {
                if (window.dataLayer && this.gtmSubmitEvent) {
                  window.dataLayer.push({ event: this.gtmSubmitEvent });
                }

                if (this.responseText) {
                  form.reset();

                  this.notificate(this.responseText, "green");
                } else if (this.responseTo) {
                  this.$router.push(this.responseTo);
                }
              } else {
                this.notificate(response.message, "red");
              }
            });
        } catch {
          this.notificate(
            "Não foi possível enviar seu voto no momento, tente mais tarde",
            "red"
          );
        }

        this.loading = false;
      }
    },
  },
  components: {
    LoadingWidget,
    ButtonWidget,
  },
};
</script>
