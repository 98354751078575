<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading && banner.img_desktop">
        <a v-bind="link">
          <img
            class="d-block item-img"
            :src="
              files +
              (xsOnly && banner.img_mobile
                ? banner.img_mobile
                : banner.img_desktop)
            "
            width="100%"
          />
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";

const _config = {
  mod: "banner",
};

export default {
  data: () => ({
    loading: true,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  computed: {
    banner() {
      return this.$rest(_config.mod).item;
    },
    link() {
      const item = this.$rest(_config.mod).item;

      if (item.link)
        return {
          target: item.target ? `_blank` : `_self`,
          href: item.link,
        };

      return "";
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  components: {
    LoadingWidget,
  },
};
</script>

<style lang="scss" scoped>
.item-img {
  aspect-ratio: 1920 / 530;
  -o-object-fit: cover;
  object-fit: cover;

  @media screen and (max-width: 600px) {
    aspect-ratio: 600 / 600;
  }
}
</style>
