<template>
  <v-sheet height="100%" class="item">
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <v-container v-if="!loading" class="py-10 py-md-13 py-lg-16">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="5" xl="6">
            <img
              v-if="item.img"
              class="d-block item-img"
              :src="files + item.img"
              width="100%"
            />

            <TitleWidget v-else>CONTATO</TitleWidget>
          </v-col>

          <v-col cols="12" md="10" lg="8">
            <v-row justify="space-around">
              <v-col
                class="py-0"
                cols="12"
                sm="6"
                md="5"
                v-for="(
                  itemsGroupedByCategory, keyItems
                ) in itemsSeparatedInTwiceColumns"
                :key="keyItems"
              >
                <template
                  v-for="(
                    { title, list }, keyitemsGroupedByCategory
                  ) in itemsGroupedByCategory"
                >
                  <div :key="keyitemsGroupedByCategory">
                    <div
                      class="
                        white--text
                        mt-8
                        text-h6 text-md-h5 text-center text-uppercase
                        font-weight-medium
                      "
                    >
                      {{ title }}
                    </div>

                    <v-btn
                      class="mt-3 font-weight-medium"
                      :href="link"
                      target="_blank"
                      rounded
                      v-for="({ title, type, link }, keyList) in list"
                      :key="keyList"
                      outlined
                      block
                      color="white"
                    >
                      <v-icon v-if="type.icon" color="white" left>
                        {{ type.icon }}
                      </v-icon>
                      {{ title }}
                    </v-btn>
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </transition>
  </v-sheet>
</template>

<script>
import TitleWidget from "../components/widgets/TitleWidget.vue";
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import groupBy from "lodash.groupby";

const _config = {
  mod: "links",
};

export default {
  data: () => ({
    loading: true,
  }),
  created() {
    const item = this.$rest("linksConfig").get({
      save: (state, data) => (state.item = data),
    });

    const items = this.$rest(_config.mod).get();

    Promise.all([item, items]).then(() => (this.loading = false));
  },
  computed: {
    item() {
      return this.$rest("linksConfig").item;
    },
    items() {
      return this.$rest(_config.mod).list;
    },
    itemsSeparatedInTwiceColumns() {
      const itemsGroupBy = groupBy(this.items, "category.title");

      const list = [];

      Object.entries(itemsGroupBy).forEach((entry) => {
        const [index, item] = entry;

        list.push({
          title: index,
          list: item,
        });
      });

      const middleIndex = Math.ceil(list.length / 2);

      return [list.splice(0, middleIndex), list.splice(-middleIndex)];
    },
  },
  components: {
    TitleWidget,
    LoadingWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  background: rgb(88, 193, 215);
  background: -moz-linear-gradient(
    180deg,
    rgba(88, 193, 215, 1) 8%,
    rgba(0, 142, 207, 1) 70%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(88, 193, 215, 1) 8%,
    rgba(0, 142, 207, 1) 70%
  );
  background: linear-gradient(
    180deg,
    rgba(88, 193, 215, 1) 8%,
    rgba(0, 142, 207, 1) 70%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#58c1d7",endColorstr="#008ecf",GradientType=1);

  &-img {
    height: 100%;
    max-height: 400px;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.item::v-deep {
  .v-btn {
    border-width: 2px !important;
  }
}
</style>
