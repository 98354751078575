<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <v-container
        v-if="!loading && items.length"
        class="py-10 py-md-13 py-lg-16"
        :fluid="mdAndDown"
      >
        <TitleWidget>Nossos Serviços</TitleWidget>

        <div class="mt-8 grid-list">
          <template v-for="(item, keyItems) in items">
            <ServiceCard
              v-if="item.img && item.title"
              :key="keyItems"
              v-bind="item"
            />
          </template>
        </div>
      </v-container>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import ServiceCard from "../cards/ServiceCard.vue";

const _config = {
  mod: "services",
  itemsPerPage: 4,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    itemsPerPage: _config.itemsPerPage,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        params: {
          itemsPerPage: this.itemsPerPage,
        },
        save: (state, data) => (this.items = data),
      })
      .then(() => (this.loading = false));
  },
  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    ServiceCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    gap: 12px;
  }
}
</style>
