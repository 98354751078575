<template>
  <FormResponsePageTemplate
    :intro="intro"
    :text="text"
    background-color="#4a31c0"
    icon-color="white"
    text-color="white"
  >
  </FormResponsePageTemplate>
</template>

<script>
import FormResponsePageTemplate from "../components/templates/FormResponsePageTemplate.vue";

export default {
  data: () => ({
    intro: "Sua mensagem foi enviada com sucesso.",
    text: "Muito obrigado!",
  }),
  components: { FormResponsePageTemplate },
};
</script>
