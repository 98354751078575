<template>
  <div v-if="map" class="map" v-html="map"></div>
</template>

<script>
export default {
  computed: {
    map() {
      return this.$rest("informations").item.map;
    },
  },
};
</script>

<style lang="scss" scoped>
.map::v-deep {
  height: 400px;

  iframe {
    display: block;
    height: 100%;
    width: 100%;
  }
}
</style>
