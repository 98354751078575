import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import VideosListView from "../views/VideosListView.vue";
import VideosItemView from "../views/VideosItemView.vue";
import ProductsListView from "../views/ProductsListView.vue";
import ServicesListView from "../views/ServicesListView.vue";
import StoresListView from "../views/StoresListView.vue";
import Error404View from "../views/Error404View.vue";
import PolicyPrivacyView from "../views/PolicyPrivacyView.vue";
import ContactResponseView from "../views/ContactResponseView.vue";
import VotingResponseView from "../views/VotingResponseView.vue";
import LinktreeListView from "../views/LinktreeListView.vue";
import MyTeacherView from "../views/MyTeacherView/Index.vue";
// import UnderConstructionView from "../views/UnderConstructionView.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "Início",
    path: "/",
    component: HomeView,
  },
  {
    name: "Sobre",
    path: "/sobre",
    component: AboutView,
  },
  {
    name: "Vídeos",
    path: "/videos",
    component: VideosListView,
  },
  {
    name: "Vídeo",
    path: "/videos/:item",
    component: VideosItemView,
  },
  {
    name: "Produtos",
    path: "/produtos",
    component: ProductsListView,
  },
  {
    name: "Serviços",
    path: "/servicos",
    component: ServicesListView,
  },
  {
    name: "Lojas",
    path: "/lojas",
    component: StoresListView,
  },
  {
    name: "Contato",
    path: "/contato",
    component: LinktreeListView,
  },
  {
    name: "Política de Privacidade",
    path: "/politica-de-privacidade",
    component: PolicyPrivacyView,
  },
  {
    name: "Meu Professor de Férias no Resort",
    path: "/professor",
    component: MyTeacherView,
  },
  {
    name: "Meu Professor de Férias no Resort",
    path: "/professor/:item",
    component: MyTeacherView,
  },
  {
    name: "Meu Professor de Férias no Resort",
    path: "/professor/:item/votar",
    component: MyTeacherView,
  },
  {
    name: "Meu Professor de Férias no Resort",
    path: "/professor/:item/votar/obrigado",
    component: VotingResponseView,
  },
  {
    name: "Obrigado!",
    path: "/professor/obrigado",
    component: ContactResponseView,
  },
  {
    path: "/erro404",
    name: "Erro 404",
    component: Error404View,
  },
  {
    path: "*",
    name: "Erro 404",
    component: Error404View,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;
