<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading && items.length" class="position-relative items">
        <v-container
          class="py-10 py-md-13 py-lg-16 position-relative"
          :fluid="mdAndDown"
        >
          <div class="grid-list">
            <template v-for="(item, keyItems) in items">
              <ProductCard
                v-if="item.img && item.title"
                :key="keyItems"
                v-bind="item"
              />
            </template>
          </div>
        </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import ProductCard from "../cards/ProductCard.vue";

const _config = {
  mod: "products",
  itemsPerPage: 10,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    itemsPerPage: _config.itemsPerPage,
  }),
  created() {
    this.get();
  },
  computed: {
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    get() {
      this.loading = true;

      this.$rest(_config.mod)
        .get({
          params: {
            itemsPerPage: this.itemsPerPage,
          },
          save: (state, data) => (this.items = data),
        })
        .then(() => (this.loading = false));
    },
  },
  watch: {
    $route() {
      this.get();
    },
  },
  components: {
    LoadingWidget,
    ProductCard,
  },
};
</script>

<style lang="scss" scoped>
.items {
  &:before {
    bottom: 0;
    content: "";
    background-image: url(../../assets/img/products-left-bg.png);
    background-position: bottom 50px left 40px;
    background-repeat: no-repeat;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  &:after {
    bottom: 0;
    content: "";
    background-image: url(../../assets/img/products-right-bg.png);
    background-position: top 50px right 40px;
    background-repeat: no-repeat;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }

  .grid-list {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(5, 1fr);

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 1fr;
      justify-content: center;
    }

    @media screen and (max-width: 600px) {
      gap: 12px;
    }
  }
}
</style>
