<template>
  <div v-if="video" class="hero-video">
    <video
      :poster="img"
      class="d-block black"
      :src="files + video"
      autoplay
      loop
      muted
    ></video>
  </div>
</template>

<script>
const _config = {
  mod: "hero-video",
};

export default {
  computed: {
    item() {
      return this.$rest(_config.mod).item;
    },
    img() {
      const item = this.item;

      if (!item.img_desktop) return "";

      return (
        this.files +
        (this.xsOnly && item.img_mobile ? item.img_mobile : item.img_desktop)
      );
    },
    video() {
      const item = this.item;

      if (!item.video_desktop) return "";

      return this.xsOnly && item.video_mobile
        ? item.video_mobile
        : item.video_desktop;
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-video {
  aspect-ratio: 1920 / 810;

  @media screen and (max-width: 600px) {
    aspect-ratio: 600 / 600;
  }

  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
</style>
