<template>
  <div>
    <div
      :class="stores && stores.length ? `hover-whatsapp` : ``"
      @click="dialog = true"
    >
      <div class="relative">
        <img
          class="d-block rounded-xl item-img"
          :src="files + img"
          :alt="title"
          width="100%"
        />
        <div v-if="stores && stores.length" class="item-img-hover">
          <v-icon color="white" size="60">fa-whatsapp</v-icon>
        </div>
      </div>

      <div class="grey--text text--darken-3 text-body-1 text-center pa-4">
        {{ title }}
      </div>
    </div>
    <v-dialog v-if="stores && stores.length" width="500px" v-model="dialog">
      <v-toolbar dark flat color="secondary">
        <div class="text-h6 white--text">Lojas:</div>
        <v-spacer></v-spacer>
        <v-btn height="40px" width="40px" fab text @click="dialog = false"
          ><v-icon size="20">fa-times</v-icon></v-btn
        >
      </v-toolbar>
      <v-card class="pa-4">
        <div
          class="my-5"
          v-for="({ title, whatsapp, xerox }, i) in stores"
          :key="i"
        >
          <div class="font-weight-medium">{{ title }}</div>
          <a
            v-if="whatsapp"
            target="_blank"
            :href="getWhatsappLinkFromPhone(whatsapp)"
            class="text-decoration-none"
          >
            <v-icon color="secondary" size="20">fa-whatsapp</v-icon>
            <span class="font-weight-bold ml-2 secondary--text">
              {{ whatsapp }}
            </span>
          </a>
          <a
            v-if="xerox"
            target="_blank"
            :href="getWhatsappLinkFromPhone(xerox)"
            class="text-decoration-none ml-5"
          >
            <v-icon color="secondary" size="20">fa-whatsapp</v-icon>
            <span class="font-weight-bold ml-2 secondary--text">
              {{ xerox }} - Xerox
            </span>
          </a>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    stores: {
      type: Array,
    },
  },
  methods: {
    getWhatsappLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.item-img {
  aspect-ratio: 262 / 145;
  -o-object-fit: cover;
  object-fit: cover;
}
.hover-whatsapp {
  cursor: pointer;
}
.item-img-hover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.hover-whatsapp:hover .item-img-hover {
  opacity: 1;
}
</style>
