export function getSocial(component) {
  const { instagram, facebook, youtube, twitter, linkedin, tiktok } = component;
  const social = {};

  if (facebook) {
    social.facebook = {
      text: "Facebook",
      link: facebook,
      icon: "fab fa-facebook-f",
      size: 20,
    };
  }

  if (instagram) {
    social.instagram = {
      text: "Instagram",
      link: instagram,
      icon: "fab fa-instagram",
      size: 20,
    };
  }

  if (youtube) {
    social.youtube = {
      text: "Youtube",
      link: youtube,
      icon: "fab fa-youtube",
      size: 20,
    };
  }

  if (twitter) {
    social.twitter = {
      text: "Twitter",
      link: twitter,
      icon: "fab fa-twitter",
      size: 18,
    };
  }

  if (linkedin) {
    social.linkedin = {
      text: "Linkedin",
      link: linkedin,
      icon: "fab fa-linkedin",
      size: 18,
    };
  }

  if (tiktok) {
    social.tiktok = {
      text: "TikTok",
      link: tiktok,
      icon: "fab fa-tiktok",
      size: 20,
    };
  }

  return social;
}
