<template>
  <v-sheet
    width="100%"
    height="100%"
    :color="backgroundColor"
    class="d-flex align-center justify-center py-10 py-md-13 py-lg-16"
  >
    <v-container>
      <div class="d-flex flex-column align-center">
        <v-icon :size="xs ? 100 : 140" :color="iconColor">
          {{ iconText }}
        </v-icon>

        <div
          class="text-h6 text-sm-h5 font-weight-bold text-center mt-7"
          :style="{ color: textColor }"
        >
          {{ intro }}
        </div>

        <div
          v-if="text"
          class="text-body-1 text-sm-h6 text-center mt-3"
          :style="{ color: textColor }"
        >
          {{ text }}
        </div>

        <ButtonWidget @click="back" :height="sm ? `40px` : `55px`" class="mt-8">
          <v-icon size="16" left> fas fa-undo </v-icon>
          Voltar
        </ButtonWidget>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
import ButtonWidget from "../widgets/ButtonWidget.vue";

export default {
  props: {
    intro: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    iconText: {
      type: String,
      default: "far fa-check-circle",
    },
    iconColor: {
      type: String,
      default: "success",
    },
    backgroundColor: {
      type: String,
      default: "transparent",
    },
    textColor: {
      type: String,
      default: "black",
    },
    next: {
      type: String,
      default: "",
    },
  },
  computed: {
    sm() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    meta() {
      return this.$route.meta;
    },
  },
  methods: {
    back() {
      !this.next
        ? window.history.back()
        : this.$router.push({ path: this.next });
    },
  },
  components: {
    ButtonWidget,
  },
};
</script>
