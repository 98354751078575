<template>
  <div class="pt-10 pt-md-13 pt-lg-16">
    <TitleWidget>Nossas Lojas</TitleWidget>

    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading">
        <div v-if="items.length" class="mt-10">
          <div
            class="item"
            :class="{ 'item-odd': keyItems % 2 !== 0 }"
            v-for="(
              {
                img,
                title,
                address,
                google_maps,
                tel,
                opening_hours,
                whatsapp,
                xerox,
              },
              keyItems
            ) in items"
            :key="keyItems"
          >
            <div class="align-center position-relative grid-list">
              <img class="d-block item-img" :src="files + img" :alt="title" />

              <v-col cols="12" lg="9" xl="8" class="px-3 px-md-16 item-caption">
                <div>
                  <TitleWidget :light="smAndDown" left>{{ title }}</TitleWidget>

                  <div
                    v-if="address"
                    class="d-flex align-center text-body-1 mt-4 mt-lg-6"
                  >
                    <a
                      v-if="google_maps"
                      class="text-decoration-none d-flex"
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                      :href="google_maps"
                      target="_blank"
                    >
                      <v-icon
                        class="mr-3"
                        :color="smAndDown ? 'white' : 'secondary'"
                        size="22"
                      >
                        fa-map-marker
                      </v-icon>
                      <span>
                        {{ address }}
                      </span>
                    </a>
                    <span
                      v-else
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                    >
                      {{ address }}
                    </span>
                  </div>

                  <v-row no-gutters>
                    <v-col v-if="tel" cols="12" sm="6">
                      <div class="d-flex align-center text-body-1 mt-4 mt-lg-6">
                        <a
                          class="text-decoration-none font-weight-bold"
                          :class="{
                            'grey--text text--darken-4': !smAndDown,
                            'white--text': smAndDown,
                          }"
                          :href="getTelLinkFromPhone(tel)"
                        >
                          <v-icon
                            class="mr-3"
                            :color="smAndDown ? 'white' : 'secondary'"
                            size="18"
                          >
                            fa-phone-alt
                          </v-icon>
                          <span>
                            {{ tel }}
                          </span>
                        </a>
                      </div>
                    </v-col>
                    <v-col v-if="whatsapp" cols="12" sm="6">
                      <div class="d-flex align-center text-body-1 mt-4 mt-lg-6">
                        <a
                          class="text-decoration-none font-weight-bold"
                          :class="{
                            'grey--text text--darken-4': !smAndDown,
                            'white--text': smAndDown,
                          }"
                          :href="getWhatsappLinkFromPhone(whatsapp)"
                        >
                          <v-icon
                            class="mr-3"
                            :color="smAndDown ? 'white' : 'secondary'"
                            size="18"
                          >
                            fa-whatsapp
                          </v-icon>
                          <span> {{ whatsapp }}</span>
                        </a>
                      </div>
                    </v-col>
                    <v-col v-if="xerox" cols="12" sm="6">
                      <div class="d-flex align-center text-body-1 mt-4 mt-lg-6">
                        <a
                          class="text-decoration-none font-weight-bold"
                          :class="{
                            'grey--text text--darken-4': !smAndDown,
                            'white--text': smAndDown,
                          }"
                          :href="getWhatsappLinkFromPhone(xerox)"
                        >
                          <v-icon
                            class="mr-3"
                            :color="smAndDown ? 'white' : 'secondary'"
                            size="18"
                          >
                            fa-whatsapp
                          </v-icon>
                          <span> {{ xerox }} - Xerox </span>
                        </a>
                      </div>
                    </v-col>
                  </v-row>

                  <div v-if="opening_hours.length" class="mt-4 mt-lg-6">
                    <div
                      class="text-body-1 font-weight-bold"
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                    >
                      Horários de Atendimento:
                    </div>

                    <div
                      class="text-body-1 mt-3"
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                    >
                      <div
                        v-for="opening_hour in opening_hours"
                        :key="opening_hour"
                      >
                        {{ opening_hour }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </div>
          </div>
        </div>

        <v-alert
          v-else
          type="info"
          :color="smAndDown ? 'white' : 'secondary'"
          class="white--text mt-10"
        >
          Nenhum item encontrado no momento.
        </v-alert>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import { getTelLinkFromPhone } from "../utils/getTelLinkFromPhone";
import { getWhatsappLinkFromPhone } from "../utils/getWhatsappLinkFromPhone";

const _config = {
  mod: "stores",
};

export default {
  data: () => ({
    loading: true,
  }),
  created() {
    this.$rest(_config.mod)
      .get()
      .then(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    getTelLinkFromPhone,
    getWhatsappLinkFromPhone,
  },
  components: {
    LoadingWidget,
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    height: 500px;
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;

    @media screen and (max-width: 600px) {
      height: 400px;
    }
  }

  .grid-list {
    display: grid;
    gap: 0;
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr;

      &:before {
        background-color: rgba(0, 0, 0, 0.4);
        bottom: 0;
        content: "";
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
      }

      .item-caption {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
        top: 0;
      }
    }
  }

  @media screen and (min-width: 960.001px) {
    &.item-odd {
      .grid-list {
        img {
          order: 12;
        }

        .item-caption {
          order: 0;
          margin-left: auto;
        }
      }
    }
  }
}
</style>
