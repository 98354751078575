<template>
  <div :class="['d-flex', alignClass]">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      class="px-14 font-weight-bold text-subtitle-1"
      :color="color ? color : !secondary ? 'primary' : 'secondary'"
      height="48px"
      style="color: #4634bb !important"
      depressed
    >
      <slot />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
      validator: (val) => ["left", "center", "right"].includes(val),
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
  },
  computed: {
    alignClass() {
      const classes = {
        left: "justify-start",
        center: "justify-center",
        right: "justify-end",
      };

      return classes[this.align];
    },
  },
};
</script>
