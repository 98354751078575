<template>
  <div class="overflow-hidden position-relative item">
    <a target="_blank" :href="link">
      <img class="d-block item-img" :src="files + img" width="100%" />

      <div
        class="
          d-flex
          align-end
          justify-center
          pa-5 pa-md-7 pa-lg-9
          item-caption
        "
      >
        <div
          class="
            text-body-1 text-sm-h6 text-md-h5 text-center
            white--text
            font-weight-black
          "
        >
          <span class="font-secondary">
            {{ title }}
          </span>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    link: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-radius: 20px;

  &-img {
    aspect-ratio: 1 / 1;
    -o-object-fit: cover;
    object-fit: cover;
  }

  &-caption {
    background: rgb(63, 63, 195);
    background: -moz-linear-gradient(
      0deg,
      rgba(63, 63, 195, 0.6) 0%,
      rgba(63, 63, 195, 0.012) 50%
    );
    background: -webkit-linear-gradient(
      0deg,
      rgba(63, 63, 195, 0.6) 0%,
      rgba(63, 63, 195, 0.012) 50%
    );
    background: linear-gradient(
      0deg,
      rgba(63, 63, 195, 0.6) 0%,
      rgba(63, 63, 195, 0.012) 50%
    );
    bottom: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3f3fc3",endColorstr="#3f3fc3",GradientType=1);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
