<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <v-container
        v-if="!loading && item.title"
        class="py-10 py-md-13 py-lg-16 item"
      >
        <TitleWidget>{{ item.title }}</TitleWidget>

        <img
          v-if="item.img"
          class="d-block mt-10 item-img"
          :src="files + item.img"
          :alt="item.title"
          width="100%"
        />

        <div
          v-if="item.text"
          class="grey--text text--darken-4 text-body-2 mt-10 item-text"
        >
          <div class="ck-content" v-html="item.text"></div>
        </div>
      </v-container>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";

const _config = {
  mod: "about",
};

export default {
  data: () => ({
    loading: true,
  }),
  created() {
    this.$rest(_config.mod)
      .get({
        save: (state, data) => (state.item = data),
      })
      .then(() => (this.loading = false));
  },
  computed: {
    item() {
      const item = this.$rest(_config.mod).item;

      const image = item.img;

      if (image && image.featured && image.featured.path)
        item.img = image.featured.path;

      if (image.list && image.list.length > 1) item.images = image.list;

      return item;
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.item {
  &-img {
    aspect-ratio: 16 / 9;
    max-height: 500px;
    -o-object-fit: contain;
    object-fit: contain;
  }
}
</style>
