<template>
  <v-app-bar
    hide-on-scroll
    elevate-on-scroll
    scroll-threshold="200"
    color="primary"
    :height="mdAndDown ? '100px' : '120px'"
    app
  >
    <v-container class="py-0" :fluid="mdAndDown">
      <v-sheet
        width="100%"
        height="100%"
        color="transparent"
        class="d-flex justify-space-between align-center"
      >
        <router-link to="/">
          <img
            v-if="informations.img"
            :src="files + informations.img"
            :alt="informations.name"
            class="d-block"
            :height="mdAndDown ? '40px' : '50px'"
          />
        </router-link>

        <div class="d-flex align-center">
          <div>
            <div
              v-for="({ text, to, dropdown }, keyMenu) in menu"
              :key="keyMenu"
              class="d-inline-flex"
            >
              <v-btn
                v-if="!dropdown"
                class="
                  grey--text
                  text--darken-3 text-body-2 text-lg-body-1 text-none
                  font-weight-medium
                  px-2 px-lg-3
                  py-5
                  nav-link
                "
                :to="to"
                tile
                depressed
                text
              >
                {{ text }}
              </v-btn>

              <v-menu v-else offset-y tile>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="
                      grey--text
                      text--darken-3 text-body-2 text-lg-body-1 text-none
                      font-weight-medium
                      px-2 px-lg-3
                      py-5
                      nav-link
                    "
                    tile
                    depressed
                    text
                  >
                    {{ text }}
                    <v-icon class="ml-3" size="12"> fa-caret-down </v-icon>
                  </v-btn>
                </template>
                <v-list tile>
                  <v-list-item
                    v-for="(item, keyDropdown) in dropdown"
                    :key="keyDropdown"
                    :to="item.to"
                  >
                    <v-list-item-title class="grey--text text--darken-3">
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>

          <div v-if="social" class="d-flex align-center ml-lg-3">
            <template v-for="(item, keySocial) in social">
              <v-btn
                v-if="item"
                :key="keySocial"
                :href="item.link"
                target="blank"
                class="ml-1 ml-lg-3 rounded-lg d-flex"
                color="secondary"
                outlined
                width="40px"
                height="40px"
                icon
              >
                <v-icon color="secondary" size="20">
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </template>
          </div>
        </div>
      </v-sheet>
    </v-container>
  </v-app-bar>
</template>

<script>
import { getTelLinkFromPhone } from "../../../utils/getTelLinkFromPhone";
import { getSocial } from "../../../utils/getSocial";

export default {
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    getTelLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.nav-link::v-deep {
  &:before {
    opacity: 0 !important;
  }

  &.v-btn--active,
  &:hover {
    .v-btn__content {
      color: var(--v-secondary-base) !important;
    }
  }
}
</style>
