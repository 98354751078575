<template>
  <div>
    <v-card
      class="align-center align-sm-start finalist-card"
      color="transparent"
      flat
      tile
    >
      <div
        class="
          text-body-1 text-sm-h6 text-lg-center
          font-weight-bold
          mt-2
          finalist-card-title
        "
      >
        {{ title }}
      </div>

      <img
        class="d-block finalist-card-img"
        :src="files + img + '?resize=1&w=360'"
        :alt="title"
        width="100%"
      />

      <v-btn
        @click="storyDialog = true"
        class="finalist-card-btn-story white--text font-weight-bold"
        color="#4a31c0"
        depressed
        block
      >
        História
      </v-btn>

      <v-btn
        @click="votingDialog = true"
        class="finalist-card-btn-voting font-weight-bold"
        color="#ffed00"
        style="color: #4a31c0"
        depressed
        block
      >
        Votar
      </v-btn>
    </v-card>

    <StoryDialog
      v-model="storyDialog"
      :title="title"
      :img="img"
      :text="text"
      @openvoting="votingDialog = true"
    />

    <VotingDialog v-model="votingDialog" :id="id" :title="title" :to="to" />
  </div>
</template>

<script>
import StoryDialog from "./StoryDialog.vue";
import VotingDialog from "./VotingDialog.vue";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    storyDialog: false,
    votingDialog: false,
  }),
  created() {
    if (this.itemId === this.id) this.openDialog();
  },
  computed: {
    params() {
      return this.$route.params;
    },
    routeMatched() {
      return this.$route.matched[0].path;
    },
    itemId() {
      return this.params.item
        ? parseFloat(this.params.item.replace(/^.*-([0-9]+)$/, "$1"))
        : null;
    },
    mdAndDown() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
  },
  methods: {
    openDialog() {
      if (this.routeMatched === "/professor/:item/votar") {
        this.storyDialog = false;
        this.votingDialog = true;
      } else if (this.routeMatched === "/professor/:item") {
        this.votingDialog = false;
        this.storyDialog = true;
      }
    },
  },
  watch: {
    $route() {
      if (this.itemId === this.id) this.openDialog();
    },
    storyDialog(v) {
      if (false === v && this.$route.path !== "/professor")
        this.$router.push({ path: "/professor" });
      else if (this.$route.path !== this.to)
        this.$router.push({ path: this.to });
    },
    votingDialog(v) {
      if (false === v && this.$route.path !== "/professor")
        this.$router.push({ path: "/professor" });
      else if (this.$route.path !== this.to + "/votar")
        this.$router.push({ path: this.to + "/votar" });
    },
  },
  components: {
    VotingDialog,
    StoryDialog,
  },
};
</script>

<style lang="scss">
.finalist-card {
  display: grid;
  grid-template: "title" "img" "btn_story" "btn_voting";
  gap: 8px;

  &-title {
    grid-area: title;
  }
  &-img {
    grid-area: img;
    aspect-ratio: 1;
    object-fit: cover;
  }
  &-btn-story {
    grid-area: btn_story;
  }
  &-btn-voting {
    grid-area: btn_voting;
  }
}

@media screen and (max-width: 1264px) {
  .finalist-card {
    grid-template:
      "img title title" auto
      "img btn_story btn_voting" 36px/ 100px 1fr 1fr;
    gap: 12px;
    height: 100px;
  }
}
</style>
