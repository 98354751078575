var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-10 pt-md-13 pt-lg-16"},[_c('TitleWidget',[_vm._v("Nossas Lojas")]),(_vm.loading)?_c('LoadingWidget'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading)?_c('div',[(_vm.items.length)?_c('div',{staticClass:"mt-10"},_vm._l((_vm.items),function(ref,keyItems){
            var img = ref.img;
            var title = ref.title;
            var address = ref.address;
            var google_maps = ref.google_maps;
            var tel = ref.tel;
            var opening_hours = ref.opening_hours;
            var whatsapp = ref.whatsapp;
            var xerox = ref.xerox;
return _c('div',{key:keyItems,staticClass:"item",class:{ 'item-odd': keyItems % 2 !== 0 }},[_c('div',{staticClass:"align-center position-relative grid-list"},[_c('img',{staticClass:"d-block item-img",attrs:{"src":_vm.files + img,"alt":title}}),_c('v-col',{staticClass:"px-3 px-md-16 item-caption",attrs:{"cols":"12","lg":"9","xl":"8"}},[_c('div',[_c('TitleWidget',{attrs:{"light":_vm.smAndDown,"left":""}},[_vm._v(_vm._s(title))]),(address)?_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[(google_maps)?_c('a',{staticClass:"text-decoration-none d-flex",class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    },attrs:{"href":google_maps,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"22"}},[_vm._v(" fa-map-marker ")]),_c('span',[_vm._v(" "+_vm._s(address)+" ")])],1):_c('span',{class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    }},[_vm._v(" "+_vm._s(address)+" ")])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[(tel)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[_c('a',{staticClass:"text-decoration-none font-weight-bold",class:{
                          'grey--text text--darken-4': !_vm.smAndDown,
                          'white--text': _vm.smAndDown,
                        },attrs:{"href":_vm.getTelLinkFromPhone(tel)}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"18"}},[_vm._v(" fa-phone-alt ")]),_c('span',[_vm._v(" "+_vm._s(tel)+" ")])],1)])]):_vm._e(),(whatsapp)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[_c('a',{staticClass:"text-decoration-none font-weight-bold",class:{
                          'grey--text text--darken-4': !_vm.smAndDown,
                          'white--text': _vm.smAndDown,
                        },attrs:{"href":_vm.getWhatsappLinkFromPhone(whatsapp)}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"18"}},[_vm._v(" fa-whatsapp ")]),_c('span',[_vm._v(" "+_vm._s(whatsapp))])],1)])]):_vm._e(),(xerox)?_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[_c('a',{staticClass:"text-decoration-none font-weight-bold",class:{
                          'grey--text text--darken-4': !_vm.smAndDown,
                          'white--text': _vm.smAndDown,
                        },attrs:{"href":_vm.getWhatsappLinkFromPhone(xerox)}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"18"}},[_vm._v(" fa-whatsapp ")]),_c('span',[_vm._v(" "+_vm._s(xerox)+" - Xerox ")])],1)])]):_vm._e()],1),(opening_hours.length)?_c('div',{staticClass:"mt-4 mt-lg-6"},[_c('div',{staticClass:"text-body-1 font-weight-bold",class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    }},[_vm._v(" Horários de Atendimento: ")]),_c('div',{staticClass:"text-body-1 mt-3",class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    }},_vm._l((opening_hours),function(opening_hour){return _c('div',{key:opening_hour},[_vm._v(" "+_vm._s(opening_hour)+" ")])}),0)]):_vm._e()],1)])],1)])}),0):_c('v-alert',{staticClass:"white--text mt-10",attrs:{"type":"info","color":_vm.smAndDown ? 'white' : 'secondary'}},[_vm._v(" Nenhum item encontrado no momento. ")])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }