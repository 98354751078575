<template>
  <div v-if="items.length" class="position-relative slides slop">
    <hooper-container
      class="slides-container"
      style="height: auto"
      ref="carousel"
      :wheelControl="false"
      :mouseDrag="items.length > 1"
      :touchDrag="items.length > 1"
      :infiniteScroll="items.length > 1"
      @slide="updateCarousel"
    >
      <hooper-slide
        class="item"
        v-for="({ img_desktop, img_mobile, link, target }, keySlide) in items"
        :key="keySlide"
      >
        <a v-if="link" :href="link" :target="target ? '_blank' : '_self'">
          <img
            :src="files + (xs && img_mobile ? img_mobile : img_desktop)"
            class="d-block item-img"
            width="100%"
          />
        </a>

        <img
          v-else
          :src="files + (xs && img_mobile ? img_mobile : img_desktop)"
          class="d-block item-img"
          width="100%"
        />
      </hooper-slide>
    </hooper-container>

    <div
      v-if="items.length > 1"
      class="d-flex justify-space-between px-3 px-md-4 px-lg-5 slides-nav"
    >
      <a
        class="
          d-flex
          align-center
          justify-center
          elevation-2
          pr-1
          slides-nav-item
        "
        @click.prevent="slidePrev"
      >
        <v-icon color="primary" :size="xs ? 20 : 30"> fa-angle-left </v-icon>
      </a>
      <a
        class="
          d-flex
          align-center
          justify-center
          elevation-2
          pl-1
          slides-nav-item
        "
        @click.prevent="slideNext"
      >
        <v-icon color="primary" :size="xs ? 20 : 30"> fa-angle-right </v-icon>
      </a>
    </div>
  </div>
</template>

<script>
const _config = {
  mod: "slides",
};

export default {
  data: () => ({
    carouselData: 0,
  }),
  computed: {
    items() {
      return this.$rest(_config.mod).list;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    },
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },
};
</script>

<style lang="scss" scoped>
.slides {
  aspect-ratio: 1920 / 810;

  @media screen and (max-width: 600px) {
    aspect-ratio: 600 / 600;
  }

  .item {
    &-img {
      aspect-ratio: 1920 / 810;
      object-fit: cover;
      -o-object-fit: cover;

      @media screen and (max-width: 600px) {
        aspect-ratio: 600 / 600;
      }
    }
  }

  &-nav {
    height: 0;
    left: 0;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    width: 100%;

    @media screen and (max-width: 600px) {
      margin-top: -20px;
    }

    &-item {
      background-color: #ffffff;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      @media screen and (max-width: 600px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.slides-container::v-deep {
  ul,
  ol {
    padding: 0 !important;
  }
}
</style>
