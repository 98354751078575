<template>
  <div v-if="!loading" class="my-teacher-view overflow-hidden">
    <div
      class="my-teacher-header position-relative"
      :class="{
        'd-flex justify-center my-teacher-header-video': !finalists.length,
      }"
    >
      <template v-if="finalists.length">
        <div class="position-relative my-teacher-header-finalists">
          <div class="d-flex flex-column align-center justify-center">
            <img
              class="d-block position-relative"
              :src="files + informations.img"
              :alt="informations.title"
            />

            <template v-if="today < finishDate">
              <div
                class="
                  text-h5 text-md-h4 text-center
                  font-weight-bold
                  mt-6
                  my-teacher-finalists-title
                "
              >
                Qual professor você deseja mandar de férias para um resort?
              </div>
            </template>
          </div>

          <template v-if="today < finishDate">
            <v-container class="py-10 my-teacher-finalists">
              <FinalistCard
                v-for="finalist in finalists"
                :key="finalist.img"
                v-bind="finalist"
              />
            </v-container>
          </template>

          <template v-else>
            <div class="d-flex justify-center">
              <v-alert class="mt-8 mt-md-10" type="info">
                Votação Encerrada
              </v-alert>
            </div>
          </template>
        </div>
      </template>

      <template v-else>
        <template v-if="informations.video">
          <v-sheet
            class="video position-relative"
            color="black"
            max-width="680px"
            width="100%"
          >
            <iframe
              width="560"
              height="315"
              :src="`https://www.youtube.com/embed/${getYoutubeCode(
                informations.video
              )}?autoplay=1`"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; mute; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </v-sheet>
        </template>

        <template v-else>
          <img
            class="d-block position-relative"
            :src="files + informations.img"
            :alt="informations.title"
          />
        </template>
      </template>
    </div>

    <template v-if="wishes.length">
      <div class="my-teacher-content position-relative">
        <v-container class="px-10 px-md-3">
          <section>
            <div class="my-teacher-section-title">Placar</div>

            <div class="d-flex py-2 item-wishe font-weight-bold">
              <div>NOME</div>

              <div class="ml-auto">VOTOS</div>
            </div>
            <div
              v-for="{ title, wishes } in wishes"
              :key="title + wishes"
              class="d-flex py-2 item-wishe"
            >
              <div>{{ title }}</div>

              <div class="ml-auto">
                {{ wishes }}
              </div>
            </div>
          </section>
        </v-container>
      </div>
    </template>

    <div
      class="my-teacher-content position-relative"
      :class="{ 'mt-10': wishes.length }"
    >
      <v-container class="px-10 px-md-3">
        <section>
          <div class="my-teacher-section-title">Premiações</div>

          <div class="my-teacher-awards mt-8">
            <div v-for="({ title, award }, id) of awards" :key="id">
              <div class="text-h5 text-md-h4 text-uppercase font-weight-bold">
                {{ title }}
              </div>

              <div
                class="my-teacher-awards-text text-h6 mt-2"
                v-html="award"
              ></div>
            </div>
          </div>
        </section>
      </v-container>

      <section class="my-teacher-timeline mt-10">
        <v-container class="px-10 px-md-3">
          <div class="my-teacher-section-title">Cronograma</div>

          <div class="mt-6">
            <template v-for="({ title, date, description }, id) of timeline">
              <div :key="id" class="pb-4">
                <div
                  class="
                    my-teacher-timeline-title
                    text-subtitle-1 text-uppercase
                    font-weight-bold
                  "
                >
                  {{ title }} ({{ date }})
                </div>

                <div class="text-subtitle-1" v-html="description"></div>
              </div>
            </template>
          </div>
        </v-container>
      </section>

      <section class="my-teacher-term">
        <v-container class="px-10 px-md-3">
          <div class="my-teacher-section-title">Regulamento</div>

          <div class="mt-8">
            <v-btn
              class="
                text-body-1 text-md-h6 text-none
                font-weight-bold
                px-10
                btn-term
              "
              :href="files + informations.term"
              target="_blank"
              color="#ffed00"
              depressed
              x-large
              rounded
            >
              Clique Aqui e Baixe o Regulamento
            </v-btn>
          </div>
        </v-container>
      </section>
    </div>
  </div>
</template>

<script>
import FinalistCard from "../../components/cards/FinalistCard/Index.vue";
import { getYoutubeCode } from "../../utils/getYoutubeCode";
import { formatToURL } from "../../utils/formatToURL";

export default {
  data: () => ({
    today: new Date(),
    finishDate: new Date("2023-10-25 17:00"),
    loading: true,
  }),
  created() {
    // this.getWishes();

    const informations = this.$rest("myteacherInformations").get({
      save: (state, data) => (state.item = data),
    });

    const finalists = this.$rest("myteacherFinalists").get();
    const awards = this.$rest("myteacherAwards").get();
    const timeline = this.$rest("myteacherTimeline").get();

    Promise.all([informations, awards, finalists, timeline]).finally(
      () => (this.loading = false)
    );
  },
  computed: {
    informations() {
      return this.$rest("myteacherInformations").item;
    },
    finalists() {
      return this.$rest("myteacherFinalists").list.map(
        ({ id, img, title, text }) => ({
          id,
          img,
          title,
          text,
          to: `/professor/${formatToURL(title)}-${id}`,
        })
      );
    },
    wishes() {
      return [];
      // return this.$rest("myteacherFinalistsWishes").list;
    },
    awards() {
      return this.$rest("myteacherAwards").list;
    },
    timeline() {
      return this.$rest("myteacherTimeline").list;
    },
  },
  methods: {
    getYoutubeCode,
    // getWishes() {
    //   this.$rest("myteacherFinalistsWishes").get();
    // },
  },
  // watch: {
  //   $route() {
  //     this.getWishes();
  //   },
  // },
  components: {
    FinalistCard,
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Brother";
  src: url("../../assets/font/brother-bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/poppins-black.ttf") format("truetype");
  font-weight: bolder;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/poppins-bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/poppins-medium.ttf") format("truetype");
  font-weight: normal;
}

.font-brother {
  font-family: "Brother" !important;
}

.font-poppins {
  font-family: "Poppins" !important;
}

.my-teacher-finalists-title {
  color: #4a31c0;
}

.my-teacher-finalists {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: flex-end;
  gap: 20px;
}

@media screen and (max-width: 1264px) {
  .my-teacher-finalists {
    grid-template-columns: auto;
  }
}

.item-wishe:not(:last-child) {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2) !important;
}

.my-teacher-view {
  color: white;
  background-color: #4a31c0;
  font-family: "Poppins";
  padding-bottom: 120px;

  @media screen and (min-width: 960.001px) {
    padding-left: 120px;
    padding-right: 120px;
  }

  .my-teacher-header {
    background-image: url(../../assets/img/header.png);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 200px;
    padding-top: 40px;

    &.my-teacher-header-video {
      padding-top: 64px;
    }

    @media screen and (min-width: 960.001px) {
      margin-left: -120px;
      margin-right: -120px;
    }

    @media screen and (max-width: 600px) {
      padding-bottom: 100px;
    }

    &:before,
    &:after {
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0;
      content: "";
      position: absolute;
      top: 0;
      width: 40%;
    }

    &:before {
      background-image: url(../../assets/img/leaves-top-left.png);
      background-position: top left;
      left: 0;
    }

    &:after {
      background-image: url(../../assets/img/leaves-top-right.png);
      background-position: top right;
      right: 0;
    }

    img {
      max-width: 300px;
      width: 100%;
    }

    &-finalists {
      z-index: 2;
    }
  }

  .my-teacher-content {
    position: relative;

    &:before {
      background-image: url(../../assets/img/leaves-right.png);
      background-position: right top;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0;
      content: "";
      position: absolute;
      right: -120px;
      top: -75px;
      width: 12%;
    }

    &:after {
      background-image: url(../../assets/img/leaves-left.png);
      background-position: left bottom;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: -200px;
      content: "";
      left: -120px;
      position: absolute;
      top: 0;
      width: 12%;
    }

    &-title {
      margin-top: -105px;

      @media screen and (max-width: 600px) {
        margin-top: -60px;
      }
    }
  }

  .my-teacher-awards {
    display: grid;
    gap: 60px;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 1264px) {
      gap: 16px;
      grid-template-columns: 1fr;
    }

    &-text {
      color: #ffed00;
    }
  }

  .my-teacher-timeline {
    position: relative;

    &:before {
      background-image: url(../../assets/img/leaves-left.png);
      background-position: left top;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0;
      content: "";
      left: -120px;
      position: absolute;
      top: 0;
      width: 12%;
    }

    &-title {
      position: relative;

      &:before {
        content: "";
        height: 10px;
        margin-right: 10px;
        position: absolute;
        right: 100%;
        top: 8px;
        width: 10px;

        @media screen and (max-width: 960px) {
          background-color: #ffed00;
          border-radius: 50%;
        }

        @media screen and (min-width: 960.001px) {
          background-image: url(../../assets/img/arrow-right.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 13px;
          margin-right: 21px;
          width: 45px;
        }
      }
    }
  }

  .my-teacher-contact {
    position: relative;

    &:before {
      background-image: url(../../assets/img/leaves-right.png);
      background-position: right top;
      background-repeat: no-repeat;
      background-size: contain;
      bottom: 0;
      content: "";
      position: absolute;
      right: -120px;
      top: 0;
      width: 12%;
    }
  }

  .my-teacher-section-title {
    align-items: center;
    aspect-ratio: 613 / 157;
    background-image: url(../../assets/img/title-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #4a31c0;
    display: inline-flex;
    font-size: 32px;
    font-weight: 900;
    margin-left: -32px;
    padding-left: 32px;
    padding-right: 100px;
    padding-top: 5px;
    text-transform: uppercase;

    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }
}

.my-teacher-contact-form::v-deep {
  .v-text-field {
    .v-input__slot {
      border: 2px solid #ffed00 !important;
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .v-icon {
      color: #4a31c0 !important;
      font-size: 20px !important;
    }
  }

  .v-input--selection-controls__input {
    margin: 0 !important;
  }

  .v-input--selection-controls__ripple {
    height: auto !important;
    margin: 0 !important;
    width: auto !important;
  }

  .v-counter {
    color: #cccccc !important;
  }
}

.btn-term {
  color: #4a31c0 !important;
}
</style>
