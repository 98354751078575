<template>
  <v-container class="py-10 py-md-13 py-lg-16">
    <TitleWidget>Vídeos</TitleWidget>
    <LoadingWidget v-if="loading" />
    <transition name="fade">
      <div v-if="!loading">
        <div v-if="youtubeVideos.length" class="mt-10 grid-list">
          <template v-for="(item, keyItems) in youtubeVideos">
            <VideoCard
              v-if="item.title && item.video"
              :key="keyItems"
              v-bind="item"
            />
          </template>
        </div>

        <v-alert v-else type="info" color="secondary" class="white--text mt-10">
          Nenhum item encontrado no momento.
        </v-alert>

        <v-pagination
          v-if="pagesLength > 1"
          class="mt-10"
          color="secondary"
          v-model="page"
          :length="pagesLength"
        ></v-pagination>
      </div>
    </transition>
  </v-container>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import VideoCard from "../components/cards/VideoCard.vue";

const _config = {
  mod: "videos",
  path: "/videos",
  itemsPerPage: 24,
  returnTotalItems: 1,
};

export default {
  data: () => ({
    loading: true,
    items: [],
    path: _config.path,
    itemsPerPage: _config.itemsPerPage,
    totalItems: 0,
    youtubeVideos: [],
  }),
  created() {
    // this.get();
    this.getYoutubeVideos();
  },
  computed: {
    page: {
      get() {
        const p = this.$route.query.p;
        return p ? parseInt(p) : 1;
      },
      set(v) {
        const query = { ...this.$route.query };
        query.p = v;

        return this.$router.push({ query });
      },
    },
    pagesLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    async getYoutubeVideos() {
      this.loading = true;
      const response = await fetch(
        "https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=UCRnv7UgTO-u5ChZC_Yg8wKw&order=date&maxResults=16&key=AIzaSyD02h8cUhsLnJjXMaxK7LLott_f_GG5Nk4"
      );
      const data = await response.json();
      this.youtubeVideos = data.items
        .filter((item) => {
          if (item.id.videoId) {
            return item;
          }
        })
        .map((item) => {
          return {
            video: `https://youtu.be/${item.id.videoId}`,
            title: item.snippet.title,
          };
        });

      this.loading = false;
    },
    // get() {
    //   this.loading = true;

    //   const params = {
    //     itemsPerPage: this.itemsPerPage,
    //     returnTotalItems: _config.returnTotalItems,
    //   };

    //   if (this.page) params.page = this.page;

    //   this.$rest(_config.mod)
    //     .get({
    //       params,
    //       keepCache: true,
    //     })
    //     .then((data) => {
    //       if (_config.returnTotalItems) {
    //         this.totalItems = data.totalItems;
    //         data = data.list;
    //       }

    //       this.items = data.map(({ id, title, video }) => ({
    //         title,
    //         video,
    //         to: `${this.path}/${formatToURL(title)}-${id}`,
    //       }));

    //       if (!data.length && this.page > 1) this.page = 1;
    //     })
    //     .then(() => (this.loading = false));
    // },
  },
  components: {
    LoadingWidget,
    TitleWidget,
    VideoCard,
  },
};
</script>

<style lang="scss" scoped>
.grid-list {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: minmax(auto, 280px);
    gap: 12px;
  }
}
</style>
