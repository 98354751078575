<template>
  <div
    class="text-h5 text-md-h4 text-uppercase font-weight-medium title-widget"
    :class="{
      'text-center': !left,
      'text-left': left,
      'grey--text text--darken-4': !light,
      'white--text': light,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    left: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.title-widget {
  padding-bottom: 13px;

  &.text-center {
    position: relative;

    &:before {
      background-color: var(--v-secondary-base);
      bottom: 0;
      content: "";
      height: 3px;
      left: 50%;
      margin-left: -40px;
      position: absolute;
      width: 80px;
    }
  }
}
</style>
