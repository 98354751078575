<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <v-container
        v-if="!loading && item.title"
        class="py-10 py-md-13 py-lg-16"
      >
        <TitleWidget>{{ item.title }}</TitleWidget>

        <div class="mt-10 black video">
          <iframe
            :src="`https://www.youtube.com/embed/${getYoutubeCode(item.video)}`"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>

        <div
          v-if="item.text"
          class="grey--text text--darken-4 text-body-2 mt-10 item-text"
        >
          <div class="ck-content" v-html="item.text"></div>
        </div>
      </v-container>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../components/widgets/LoadingWidget.vue";
import TitleWidget from "../components/widgets/TitleWidget.vue";
import { getYoutubeCode } from "../utils/getYoutubeCode";

const _config = {
  mod: "videos",
};

export default {
  data: () => ({
    loading: true,
    item: {},
  }),
  created() {
    this.get();
  },
  computed: {
    itemId() {
      return parseFloat(this.$route.params.item.replace(/^.*-([0-9]+)$/, "$1"));
    },
  },
  methods: {
    get() {
      this.loading = true;

      this.$rest(_config.mod)
        .get({
          id: this.itemId,
        })
        .then((data) => {
          this.item = data;
        })
        .then(() => (this.loading = false));
    },
    getYoutubeCode,
  },
  watch: {
    itemId() {
      this.get();
    },
    $route() {
      this.get();
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
  },
};
</script>
