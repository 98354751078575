const server =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_SERVER
    : location.origin;

const menu = [
  {
    text: "Sobre",
    to: "/sobre",
  },
  {
    text: "Produtos",
    to: "/produtos",
  },
  {
    text: "Serviços",
    to: "/servicos",
  },
  {
    text: "Lojas",
    to: "/lojas",
  },
  {
    text: "Vídeos",
    to: "/videos",
  },
  {
    text: "Contato",
    to: "/contato",
  },
  // {
  //   text: "Fale Conosco",
  //   to: "/fale-conosco",
  // },
];

export { server, menu };
