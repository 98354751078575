var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('LoadingWidget'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading && _vm.items.length)?_c('div',{staticClass:"position-relative slides"},[_c('hooper-container',{ref:"carousel",staticClass:"slides-container",staticStyle:{"height":"auto"},attrs:{"wheelControl":false,"mouseDrag":_vm.items.length > 1,"touchDrag":_vm.items.length > 1,"infiniteScroll":_vm.items.length > 1},on:{"slide":_vm.updateCarousel}},_vm._l((_vm.items),function(ref,keyItems){
            var img = ref.img;
            var title = ref.title;
            var address = ref.address;
            var google_maps = ref.google_maps;
            var tel = ref.tel;
            var opening_hours = ref.opening_hours;
            var whatsapp = ref.whatsapp;
            var xerox = ref.xerox;
return _c('hooper-slide',{key:keyItems,staticClass:"item"},[_c('div',{staticClass:"align-center position-relative grid-list"},[_c('img',{staticClass:"d-block pr-md-8 item-img",attrs:{"src":_vm.files + img,"alt":title}}),_c('v-col',{staticClass:"px-md-8 item-caption",attrs:{"cols":"12","lg":"9","xl":"7"}},[_c('div',[_c('TitleWidget',{attrs:{"light":_vm.smAndDown,"left":""}},[_vm._v(_vm._s(title))]),(address)?_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[(google_maps)?_c('a',{staticClass:"text-decoration-none d-flex",class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    },attrs:{"href":google_maps,"target":"_blank"}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"22"}},[_vm._v(" fa-map-marker ")]),_c('span',[_vm._v(" "+_vm._s(address)+" ")])],1):_c('span',{class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    }},[_vm._v(" "+_vm._s(address)+" ")])]):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[(tel)?_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[_c('a',{staticClass:"text-decoration-none font-weight-bold",class:{
                          'grey--text text--darken-4': !_vm.smAndDown,
                          'white--text': _vm.smAndDown,
                        },attrs:{"href":_vm.getTelLinkFromPhone(tel)}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"18"}},[_vm._v(" fa-phone-alt ")]),_c('span',[_vm._v(" "+_vm._s(tel)+" ")])],1)])]):_vm._e(),(whatsapp)?_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[_c('a',{staticClass:"text-decoration-none font-weight-bold",class:{
                          'grey--text text--darken-4': !_vm.smAndDown,
                          'white--text': _vm.smAndDown,
                        },attrs:{"href":_vm.getWhatsappLinkFromPhone(whatsapp)}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"18"}},[_vm._v(" fa-whatsapp ")]),_c('span',[_vm._v(" "+_vm._s(whatsapp)+" ")])],1)])]):_vm._e(),(xerox)?_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center text-body-1 mt-4 mt-lg-6"},[_c('a',{staticClass:"text-decoration-none font-weight-bold",class:{
                          'grey--text text--darken-4': !_vm.smAndDown,
                          'white--text': _vm.smAndDown,
                        },attrs:{"href":_vm.getWhatsappLinkFromPhone(xerox)}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.smAndDown ? 'white' : 'secondary',"size":"18"}},[_vm._v(" fa-whatsapp ")]),_c('span',[_vm._v(" "+_vm._s(xerox)+" - Xerox ")])],1)])]):_vm._e()],1),(opening_hours.length)?_c('div',{staticClass:"mt-4 mt-lg-6"},[_c('div',{staticClass:"text-body-1 font-weight-bold",class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    }},[_vm._v(" Horários de Atendimento: ")]),_c('div',{staticClass:"text-body-1 mt-3",class:{
                      'grey--text text--darken-4': !_vm.smAndDown,
                      'white--text': _vm.smAndDown,
                    }},_vm._l((opening_hours),function(opening_hour){return _c('div',{key:opening_hour},[_vm._v(" "+_vm._s(opening_hour)+" ")])}),0)]):_vm._e()],1)])],1)])}),1),(_vm.items.length > 1)?_c('div',{staticClass:"\n          d-flex\n          justify-center justify-sm-space-between\n          px-3 px-md-4 px-lg-5\n          slides-nav\n        "},[_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            pr-1\n            slides-nav-item\n            cursor-pointer\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slidePrev.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":"white","size":_vm.xs ? 20 : 30}},[_vm._v(" fa-angle-left ")])],1),_c('a',{staticClass:"\n            d-flex\n            align-center\n            justify-center\n            pl-1\n            slides-nav-item\n            cursor-pointer\n          ",on:{"click":function($event){$event.preventDefault();return _vm.slideNext.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":_vm.smAndDown ? 'white' : 'grey darken-2',"size":_vm.xs ? 20 : 30}},[_vm._v(" fa-angle-right ")])],1)]):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }