<template>
  <v-dialog
    @input="(v) => $emit('input', v)"
    :value="value"
    scrollable
    max-width="960px"
  >
    <v-card>
      <v-card-title class="justify-center justify-sm-start font-weight-bold">
        {{ title }}
      </v-card-title>
      <v-card-text class="item-dialog-content">
        <img
          class="
            float-sm-left
            mx-auto
            ml-sm-0
            mr-sm-3
            mb-3
            d-block
            item-dialog-img
          "
          :src="files + img + '?resize=1&w=360'"
          :alt="title"
          width="100%"
        />

        <div class="item-dialog-text" v-html="text"></div>
      </v-card-text>

      <v-card-actions class="justify-end mt-1">
        <v-btn
          @click="$emit('input', false)"
          class="px-4"
          color="grey"
          depressed
          text
        >
          Fechar
        </v-btn>

        <v-btn
          @click="$emit('openvoting')"
          class="font-weight-bold px-4"
          color="#ffed00"
          style="color: #4a31c0"
          depressed
        >
          Votar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.item-dialog-content {
  img {
    max-width: 300px;
    width: 100%;
  }

  &:after {
    clear: both;
    content: "";
    display: table;
  }

  .item-dialog-text {
    &:after {
      clear: both;
      content: "";
      display: table;
    }
  }
}
</style>
