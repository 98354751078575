<template>
  <v-card @click="dialog = true" class="item" flat tile>
    <div class="position-relative">
      <img
        class="d-block item-img"
        :src="`//img.youtube.com/vi/${getYoutubeCode(video)}/hqdefault.jpg`"
        width="100%"
      />

      <div class="d-flex align-center justify-center play-icon">
        <v-icon color="white" size="40"> fa-play </v-icon>
      </div>
    </div>

    <div
      class="
        grey--text
        text--darken-4 text-body-1 text-md-h6
        font-weight-medium
        pa-4
        item-title
      "
    >
      {{ title }}
    </div>
    <v-dialog max-width="1080px" v-model="dialog">
      <div class="video black relative">
        <v-btn @click="dialog = false" class="close-btn" color="white" icon>
          <v-icon>fa-times</v-icon>
        </v-btn>
        <iframe
          :src="
            dialog
              ? `https://www.youtube.com/embed/${getYoutubeCode(
                  video
                )}?autoplay=1`
              : ``
          "
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </v-dialog>
  </v-card>
</template>

<script>
import { getYoutubeCode } from "../../utils/getYoutubeCode";

export default {
  data: () => ({
    dialog: false,
  }),
  props: {
    title: {
      type: String,
      required: true,
    },
    video: {
      type: String,
      required: true,
    },
  },
  methods: {
    getYoutubeCode,
  },
};
</script>

<style>
.v-dialog {
  position: relative;
  overflow: initial;
}
</style>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  top: -50px;
  right: -50px;
  @media (max-width: 1200px) {
    top: 0;
    right: 0;
  }
}
.item {
  &-img {
    aspect-ratio: 16 / 9;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .play-icon {
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;
    pointer-events: none;
    left: 0;
    opacity: 0.6;
    position: absolute;
    right: 0;
    text-shadow: 0 0 5px transparent;
    top: 0;
    transition: all 0.1s linear;
  }

  &:hover {
    .play-icon {
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 1;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    }
  }
}
</style>
