<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading && items.length" class="position-relative slides">
        <hooper-container
          class="slides-container"
          style="height: auto"
          ref="carousel"
          :wheelControl="false"
          :mouseDrag="items.length > 1"
          :touchDrag="items.length > 1"
          :infiniteScroll="items.length > 1"
          @slide="updateCarousel"
        >
          <hooper-slide
            class="item"
            v-for="(
              {
                img,
                title,
                address,
                google_maps,
                tel,
                opening_hours,
                whatsapp,
                xerox,
              },
              keyItems
            ) in items"
            :key="keyItems"
          >
            <div class="align-center position-relative grid-list">
              <img
                class="d-block pr-md-8 item-img"
                :src="files + img"
                :alt="title"
              />

              <v-col cols="12" lg="9" xl="7" class="px-md-8 item-caption">
                <div>
                  <TitleWidget :light="smAndDown" left>{{ title }}</TitleWidget>

                  <div
                    v-if="address"
                    class="d-flex align-center text-body-1 mt-4 mt-lg-6"
                  >
                    <a
                      v-if="google_maps"
                      class="text-decoration-none d-flex"
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                      :href="google_maps"
                      target="_blank"
                    >
                      <v-icon
                        class="mr-3"
                        :color="smAndDown ? 'white' : 'secondary'"
                        size="22"
                      >
                        fa-map-marker
                      </v-icon>
                      <span>
                        {{ address }}
                      </span>
                    </a>
                    <span
                      v-else
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                    >
                      {{ address }}
                    </span>
                  </div>
                  <v-row no-gutters>
                    <v-col v-if="tel" cols="6">
                      <div class="d-flex align-center text-body-1 mt-4 mt-lg-6">
                        <a
                          class="text-decoration-none font-weight-bold"
                          :class="{
                            'grey--text text--darken-4': !smAndDown,
                            'white--text': smAndDown,
                          }"
                          :href="getTelLinkFromPhone(tel)"
                        >
                          <v-icon
                            class="mr-3"
                            :color="smAndDown ? 'white' : 'secondary'"
                            size="18"
                          >
                            fa-phone-alt
                          </v-icon>
                          <span>
                            {{ tel }}
                          </span>
                        </a>
                      </div>
                    </v-col>
                    <v-col v-if="whatsapp" cols="6">
                      <div class="d-flex align-center text-body-1 mt-4 mt-lg-6">
                        <a
                          class="text-decoration-none font-weight-bold"
                          :class="{
                            'grey--text text--darken-4': !smAndDown,
                            'white--text': smAndDown,
                          }"
                          :href="getWhatsappLinkFromPhone(whatsapp)"
                        >
                          <v-icon
                            class="mr-3"
                            :color="smAndDown ? 'white' : 'secondary'"
                            size="18"
                          >
                            fa-whatsapp
                          </v-icon>
                          <span>
                            {{ whatsapp }}
                          </span>
                        </a>
                      </div>
                    </v-col>
                    <v-col v-if="xerox" cols="6">
                      <div class="d-flex align-center text-body-1 mt-4 mt-lg-6">
                        <a
                          class="text-decoration-none font-weight-bold"
                          :class="{
                            'grey--text text--darken-4': !smAndDown,
                            'white--text': smAndDown,
                          }"
                          :href="getWhatsappLinkFromPhone(xerox)"
                        >
                          <v-icon
                            class="mr-3"
                            :color="smAndDown ? 'white' : 'secondary'"
                            size="18"
                          >
                            fa-whatsapp
                          </v-icon>
                          <span> {{ xerox }} - Xerox </span>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                  <div v-if="opening_hours.length" class="mt-4 mt-lg-6">
                    <div
                      class="text-body-1 font-weight-bold"
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                    >
                      Horários de Atendimento:
                    </div>

                    <div
                      class="text-body-1 mt-3"
                      :class="{
                        'grey--text text--darken-4': !smAndDown,
                        'white--text': smAndDown,
                      }"
                    >
                      <div
                        v-for="opening_hour in opening_hours"
                        :key="opening_hour"
                      >
                        {{ opening_hour }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
            </div>
          </hooper-slide>
        </hooper-container>

        <div
          v-if="items.length > 1"
          class="
            d-flex
            justify-center justify-sm-space-between
            px-3 px-md-4 px-lg-5
            slides-nav
          "
        >
          <a
            class="
              d-flex
              align-center
              justify-center
              pr-1
              slides-nav-item
              cursor-pointer
            "
            @click.prevent="slidePrev"
          >
            <v-icon color="white" :size="xs ? 20 : 30"> fa-angle-left </v-icon>
          </a>
          <a
            class="
              d-flex
              align-center
              justify-center
              pl-1
              slides-nav-item
              cursor-pointer
            "
            @click.prevent="slideNext"
          >
            <v-icon
              :color="smAndDown ? 'white' : 'grey darken-2'"
              :size="xs ? 20 : 30"
            >
              fa-angle-right
            </v-icon>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../widgets/LoadingWidget.vue";
import TitleWidget from "../widgets/TitleWidget.vue";
import { getTelLinkFromPhone } from "../../utils/getTelLinkFromPhone";
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";

const _config = {
  mod: "stores",
};

export default {
  data: () => ({
    loading: true,
    carouselData: 0,
  }),
  created() {
    this.$rest(_config.mod)
      .get()
      .then(() => (this.loading = false));
  },
  computed: {
    items() {
      return this.$rest(_config.mod).list;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    xs() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    slidePrev() {
      this.$refs.carousel.slidePrev();
    },
    slideNext() {
      this.$refs.carousel.slideNext();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    },
    getTelLinkFromPhone,
    getWhatsappLinkFromPhone,
  },
  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },
  components: {
    LoadingWidget,
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.slides {
  .item {
    &-img {
      height: 500px;
      object-fit: cover;
      -o-object-fit: cover;
      width: 100%;

      @media screen and (max-width: 600px) {
        height: 400px;
      }
    }

    .grid-list {
      display: grid;
      gap: 0;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 960px) {
        grid-template-columns: 1fr;

        &:before {
          background-color: rgba(0, 0, 0, 0.4);
          bottom: 0;
          content: "";
          left: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: 0;
        }

        .item-caption {
          align-items: center;
          bottom: 0;
          display: flex;
          justify-content: center;
          left: 0;
          position: absolute;
          right: 0;
          text-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
          top: 0;
        }
      }
    }
  }

  &-nav {
    height: 0;
    left: 0;
    margin-top: -25px;
    position: absolute;
    top: 50%;
    width: 100%;

    @media screen and (max-width: 600px) {
      top: auto;
      bottom: 50px;
      margin-top: 0;
    }

    &-item {
      border-radius: 50%;
      height: 50px;
      width: 50px;

      @media screen and (max-width: 600px) {
        height: 40px;
        width: 40px;
      }
    }
  }
}

.slides-container::v-deep {
  ul,
  ol {
    padding: 0 !important;
  }
}
</style>
