<template>
  <div>
    <LoadingWidget v-if="loading" />

    <transition name="fade">
      <div v-if="!loading">
        <HeroVideo v-if="video.video_desktop" />
        <HeroSlides v-else-if="slides.length" />
      </div>
    </transition>
  </div>
</template>

<script>
import LoadingWidget from "../../widgets/LoadingWidget.vue";
import HeroVideo from "./HeroVideo.vue";
import HeroSlides from "./HeroSlides.vue";

export default {
  data: () => ({
    loading: true,
  }),
  created() {
    const heroVideo = this.$rest("hero-video").get({
      save: (state, data) => (state.item = data),
    });

    const heroSlides = this.$rest("slides").get();

    Promise.all([heroVideo, heroSlides]).then(() => (this.loading = false));
  },
  computed: {
    video() {
      return this.$rest("hero-video").item;
    },
    slides() {
      return this.$rest("slides").list;
    },
  },
  components: {
    LoadingWidget,
    HeroVideo,
    HeroSlides,
  },
};
</script>
