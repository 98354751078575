<template>
  <footer class="shell-footer">
    <v-sheet color="primary" class="position-relative">
      <v-container class="pt-10 pt-md-13 py-lg-16 pb-5 pb-md-8 pb-lg-11">
        <div class="grid-list">
          <div class="grid-list-item">
            <router-link to="/">
              <img
                class="d-block mx-auto mx-md-0"
                src="../../assets/img/mg-papelaria-square.jpg"
                height="154px"
              />
            </router-link>
          </div>

          <div class="d-flex flex-column align-start grid-list-item">
            <v-sheet
              class="
                grey--text
                text--darken-4 text-body-1 text-md-h6
                font-weight-bold
                mb-3
              "
              width="100%"
              color="transparent"
            >
              MAPA DO SITE
            </v-sheet>

            <div class="mx-n2 mx-lg-n3">
              <v-btn
                class="
                  text-body-2 text-lg-body-1 text-none
                  px-2 px-lg-3
                  py-2
                  nav-link
                "
                active-class="font-weight-bold"
                min-width="auto"
                height="32px"
                to="/"
                tile
                depressed
                text
              >
                Início
              </v-btn>
            </div>

            <div
              v-for="({ text, to, dropdown }, keyMenu) in menu"
              :key="keyMenu"
              class="mx-n2 mx-lg-n3"
            >
              <v-btn
                v-if="!dropdown"
                class="
                  text-body-2 text-lg-body-1 text-none
                  px-2 px-lg-3
                  py-2
                  nav-link
                "
                active-class="font-weight-bold"
                min-width="auto"
                height="32px"
                :to="to"
                tile
                depressed
                text
              >
                {{ text }}
              </v-btn>

              <v-menu v-else offset-y tile>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="
                      text-caption text-lg-body-1 text-none
                      px-2 px-lg-3
                      py-2
                      nav-link
                    "
                    active-class="font-weight-bold"
                    height="32px"
                    tile
                    depressed
                    text
                  >
                    {{ text }}
                    <v-icon class="ml-3" size="12"> fa-caret-down </v-icon>
                  </v-btn>
                </template>
                <v-list tile>
                  <v-list-item
                    v-for="(item, keyDropdown) in dropdown"
                    :key="keyDropdown"
                    :to="item.to"
                  >
                    <v-list-item-title class="grey--text text--darken-3">
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>

          <div class="grid-list-item">
            <div
              v-if="!xsOnly || $route.path !== '/contato'"
              class="d-flex flex-column align-start"
            >
              <v-sheet
                v-if="contacts.length"
                class="
                  grey--text
                  text--darken-4 text-body-1 text-md-h6
                  font-weight-bold
                  mb-2
                "
                width="100%"
                color="transparent"
              >
                FALE CONOSCO
              </v-sheet>

              <template
                v-for="(
                  { title, title_short, tel, whatsapp, xerox }, keyContacts
                ) in contacts"
              >
                <div class="mb-5" :key="keyContacts">
                  <span>
                    {{ title_short || title }}
                  </span>
                  <div class="d-flex mt-1 flex-wrap">
                    <a
                      v-if="tel"
                      class="
                        grey--text
                        text--darken-3 text-decoration-none
                        mr-5
                      "
                      :href="getTelLinkFromPhone(tel)"
                    >
                      <div class="d-flex align-center">
                        <v-icon color="secondary" size="18">
                          fa-phone-alt
                        </v-icon>
                        <span class="secondary--text font-weight-bold ml-1">
                          {{ tel }}
                        </span>
                      </div>
                    </a>
                    <a
                      v-if="whatsapp"
                      class="grey--text text--darken-3 text-decoration-none"
                      :key="keyContacts"
                      :href="getWhatsappLinkFromPhone(whatsapp)"
                    >
                      <div class="d-flex align-center">
                        <v-icon color="secondary" size="20">fa-whatsapp</v-icon>
                        <span class="secondary--text font-weight-bold ml-1">
                          {{ whatsapp }}
                        </span>
                      </div>
                    </a>
                  </div>
                  <div v-if="xerox" class="mt-md-1">
                    <a
                      class="grey--text text--darken-3 text-decoration-none"
                      :key="keyContacts"
                      :href="getWhatsappLinkFromPhone(xerox)"
                    >
                      <div class="d-flex align-center">
                        <v-icon color="secondary" size="20">fa-whatsapp</v-icon>
                        <span class="secondary--text font-weight-bold ml-1">
                          {{ xerox }} - Xerox
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="grid-item">
            <div class="d-flex justify-center justify-md-end align-center">
              <template v-for="(item, keySocial) in social">
                <div v-if="item" :key="keySocial" class="d-inline-flex">
                  <v-btn
                    :href="item.link"
                    target="blank"
                    class="mx-1 rounded-lg d-flex"
                    color="secondary"
                    outlined
                    width="40px"
                    height="40px"
                    icon
                  >
                    <v-icon color="secondary" size="20">{{ item.icon }}</v-icon>
                  </v-btn>
                </div>
              </template>
            </div>
          </div>
        </div>
      </v-container>
    </v-sheet>

    <v-sheet color="secondary">
      <v-container
        class="
          pt-0
          pb-5
          d-flex
          flex-column flex-sm-row
          justify-space-between
          align-center
          text-center
        "
      >
        <div class="d-flex flex-column align-start">
          <div class="white--text text-body-2 mt-5">
            <b class="font-weight-bold">
              {{ informations.name }}
            </b>
            © {{ year }}
            <span class="d-block d-sm-inline-block">
              Todos os direitos reservados
            </span>
          </div>
          <div class="white--text text-body-2">
            <span class="d-block d-sm-inline-block">
              CNPJ: 04.576.775/0001-60 Razão Social: Migliorini & Migliorini
              Ltda
            </span>
          </div>
        </div>
        <a
          class="d-flex mt-5"
          href="https://www.mrxweb.com.br/"
          target="_blank"
        >
          <img
            class="shell-footer-logo"
            src="../../assets/img/mrxweb.svg"
            alt="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            title="MRX Web Sites, Publicidade, Marketing Digital, Produção de Vídeos - Sinop/MT"
            height="20px"
          />
        </a>
      </v-container>
    </v-sheet>
  </footer>
</template>

<script>
import { getTelLinkFromPhone } from "../../utils/getTelLinkFromPhone";
import { getWhatsappLinkFromPhone } from "../../utils/getWhatsappLinkFromPhone";
import { getSocial } from "../../utils/getSocial";
import { menu } from "../../config";

export default {
  data: () => ({
    year: new Date().getFullYear(),
    menu,
    contacts: [],
  }),
  created() {
    this.$rest("stores").get({
      save: (state, data) => (this.contacts = data),
    });
  },
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    social() {
      return getSocial(this.informations);
    },
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  methods: {
    getTelLinkFromPhone,
    getWhatsappLinkFromPhone,
  },
};
</script>

<style lang="scss" scoped>
.shell-footer {
  .grid-list {
    display: grid;
    gap: 24px 48px;
    grid-template-columns: repeat(4, auto);
    justify-content: space-between;

    @media screen and (max-width: 1264px) {
      grid-template-columns: repeat(3, auto);
    }

    @media screen and (min-width: 960.001px) and (max-width: 1264px) {
      > :last-child {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 4;
        justify-content: center;
      }
    }

    @media screen and (min-width: 490.001px) and (max-width: 960px) {
      > :first-child {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 4;
        justify-content: center;
      }
    }

    @media screen and (min-width: 490.001px) and (max-width: 760px) {
      grid-template-columns: auto auto;

      > :first-child,
      > :last-child {
        display: grid;
        grid-column-start: 1;
        grid-column-end: 3;
        justify-content: center;
      }
    }

    @media screen and (max-width: 490px) {
      grid-template-columns: minmax(auto, 300px);
      justify-content: center;
    }

    .btn-contact::v-deep {
      .v-btn__content {
        max-width: 100%;
        white-space: normal;
      }
    }
  }

  .nav-link::v-deep {
    &:before {
      opacity: 0 !important;
    }

    &.v-btn--active,
    &:hover {
      .v-btn__content {
        color: var(--v-secondary-base) !important;
      }
    }
  }
}
</style>
