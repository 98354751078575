var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"item",attrs:{"height":"100%"}},[(_vm.loading)?_c('LoadingWidget'):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!_vm.loading)?_c('v-container',{staticClass:"py-10 py-md-13 py-lg-16"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"5","xl":"6"}},[(_vm.item.img)?_c('img',{staticClass:"d-block item-img",attrs:{"src":_vm.files + _vm.item.img,"width":"100%"}}):_c('TitleWidget',[_vm._v("CONTATO")])],1),_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-row',{attrs:{"justify":"space-around"}},_vm._l((_vm.itemsSeparatedInTwiceColumns),function(itemsGroupedByCategory,keyItems){return _c('v-col',{key:keyItems,staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"5"}},[_vm._l((itemsGroupedByCategory),function(ref,keyitemsGroupedByCategory){
var title = ref.title;
var list = ref.list;
return [_c('div',{key:keyitemsGroupedByCategory},[_c('div',{staticClass:"\n                      white--text\n                      mt-8\n                      text-h6 text-md-h5 text-center text-uppercase\n                      font-weight-medium\n                    "},[_vm._v(" "+_vm._s(title)+" ")]),_vm._l((list),function(ref,keyList){
var title = ref.title;
var type = ref.type;
var link = ref.link;
return _c('v-btn',{key:keyList,staticClass:"mt-3 font-weight-medium",attrs:{"href":link,"target":"_blank","rounded":"","outlined":"","block":"","color":"white"}},[(type.icon)?_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v(" "+_vm._s(type.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(title)+" ")],1)})],2)]})],2)}),1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }